<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout my-3">
          <div class="md-layout-item">
            <md-button
              class="md-primary md-just-icon md-round float-right"
              @click="newAssessment(false)"
              @click.middle="newAssessment(true)"
            >
              <md-icon>
                add
              </md-icon>
              <md-tooltip>New Assessment</md-tooltip>
            </md-button>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getAssessments"
              @onShowDetails="onShowDetails"
              @onDelete="onDelete"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';

export default {
  components: {
    VueTable,
  },
  props: [],
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'course_level.name',
            mask: 'course level',
            sortable: true,
          },
          {
            title: 'title',
            sortable: true,
            max_chars: 50,
          },
          {
            title: 'description',
            sortable: true,
            max_chars: 100,
          },
          {
            title: 'date',
            dateFormat: true,
            sortable: true,
          },
        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Show Details',
            callback: 'onShowDetails',
            icon: 'search',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Delete Assessment',
            callback: 'onDelete',
            icon: 'close',
          },
        ],
        values: {},
      },
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getAssessments(params) {
      this.request(this.$API.ASSESSMENTS.paginableUrl(params), 'get', null, ({ data }) => {
        this.vTable.values = data;
      });
    },
    newAssessment(isMiddleClick = false) {
      this.openLink({ name: 'NewAssessment' }, isMiddleClick);
    },
    onShowDetails(item, isMiddleClick) {
      this.openLink({ name: 'AssessmentDetails', params: { id: item.assessment_id } }, isMiddleClick);
    },
    onDelete(item) {
      this.fireConfirm(
        'Deleting Assessment',
        'Are you sure you want to Delete this Assessment?',
      ).then(() => {
        this.request(`${this.$API.ASSESSMENTS}${item.assessment_id}`, 'delete', null, () => {
          this.fireSuccess('Assessment deleted succesfully');
          this.$refs.vtable.init();
        });
      });
    },
  },
};
</script>

<style scoped>
</style>
